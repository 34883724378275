var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_bank") } },
        [
          _c(
            "a-tabs",
            { attrs: { type: "card" } },
            [
              _c(
                "a-tab-pane",
                {
                  key: "1",
                  staticClass: "bordertabs p-3",
                  scopedSlots: _vm._u([
                    {
                      key: "tab",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { attrs: { "data-testid": "master-bank" } },
                            [_vm._v(_vm._s(_vm.$t("lbl_master_bank")))]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "a-card",
                    { staticStyle: { "margin-bottom": "1rem" } },
                    [
                      _c(
                        "a-row",
                        { staticStyle: { "margin-bottom": "1rem" } },
                        [
                          _c("a-col", { attrs: { span: 11 } }, [
                            _c("h2", [_vm._v(_vm._s(_vm.$t("lbl_bank")))])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "a-form",
                        {
                          attrs: {
                            layout: "vertical",
                            form: _vm.form,
                            "label-col": { span: 8 },
                            "wrapper-col": { span: 14 }
                          }
                        },
                        [
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(_vm.formRules.name.label)
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: _vm.formRules.name.decorator,
                                            expression:
                                              "formRules.name.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.name.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.name.placeholder
                                          ),
                                          autocomplete: "off"
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.handleValidation(
                                              $event,
                                              "name",
                                              false,
                                              "master",
                                              "wordOnly"
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(_vm.formRules.code.label)
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: _vm.formRules.code.decorator,
                                            expression:
                                              "formRules.code.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.code.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.code.placeholder
                                          ),
                                          autocomplete: "off"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.country.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.country.decorator,
                                            expression:
                                              "formRules.country.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.country.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.country.placeholder
                                          ),
                                          autocomplete: "off"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.active.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-switch", {
                                        model: {
                                          value: _vm.isActive,
                                          callback: function($$v) {
                                            _vm.isActive = $$v
                                          },
                                          expression: "isActive"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form-item",
                                    [
                                      _c(
                                        "a-space",
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "danger" },
                                              on: { click: _vm.handleCancel }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_cancel")) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _vm.$can("create", "bank")
                                            ? _c(
                                                "a-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    "html-type": "submit",
                                                    loading: _vm.loadingCreate
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.submitForm(
                                                        "create"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("lbl_create")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticStyle: { "margin-bottom": "1rem" } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12, xs: 24, sm: 24, md: 12 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c("InputSearch", {
                                staticStyle: { width: "98%" },
                                on: { "input-search": _vm.reponseSearchInput }
                              }),
                              _c("a-button", {
                                attrs: { type: "primary", icon: "reload" },
                                on: {
                                  click: function($event) {
                                    return _vm.resetFilter()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    {
                      staticClass: "mb-2",
                      attrs: { type: "flex", gutter: [16, 16] }
                    },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table1",
                              selectedRowKeys: _vm.selectedRowKeys,
                              dataSource: _vm.dataSource,
                              columns: _vm.columnsTableMaster,
                              scroll: { x: 1000 },
                              paginationcustom: true,
                              defaultPagination: false,
                              loading: _vm.loadingTableMaster
                            },
                            on: { "on-edit": _vm.responseEditTableMaster }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c("Pagination", {
                            attrs: {
                              total: _vm.totalElementsBank,
                              pageSizeSet: _vm.limitBank,
                              idPagination: "pagination1"
                            },
                            on: {
                              "response-pagesize-change":
                                _vm.responsePageSizeChangeBank,
                              "response-currentpage-change":
                                _vm.responseCurrentPageChangeBank
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          staticClass: "mt-2",
                          attrs: { span: 12, align: "end" }
                        },
                        [
                          _c(
                            "a-tag",
                            {
                              staticStyle: { "font-size": "13px" },
                              attrs: { color: "grey" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_total_found")) +
                                  " : " +
                                  _vm._s(_vm.totalElementsBank) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "2",
                  staticClass: "bordertabs p-3",
                  scopedSlots: _vm._u([
                    {
                      key: "tab",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            { attrs: { "data-testid": "company-bank" } },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("lbl_list_of_companys_bank"))
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "a-row",
                    { staticStyle: { "margin-bottom": "1rem" } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12, xs: 24, sm: 24, md: 12 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c("InputSearch", {
                                staticStyle: { width: "98%" },
                                on: {
                                  "input-search":
                                    _vm.reponseSearchInputCompanyBank
                                }
                              }),
                              _c("a-button", {
                                attrs: { type: "primary", icon: "reload" },
                                on: { click: _vm.resetFilterCompanyBank }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.$can("create", "sync-company-bank") ||
                      _vm.$can("create", "bank")
                        ? _c(
                            "a-col",
                            { attrs: { span: 12, align: "end" } },
                            [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.lastSyncTitle,
                                        placement: "left"
                                      }
                                    },
                                    [
                                      _vm.$can("create", "sync-company-bank")
                                        ? _c("a-button", {
                                            attrs: {
                                              icon: "file-sync",
                                              "data-testid":
                                                "company-bank-sync",
                                              loading: _vm.loadingSync
                                            },
                                            on: { click: _vm.handleSync }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.$can("create", "bank")
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: { click: _vm.handleAddNew }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_create_new")) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    {
                      staticClass: "mb-2",
                      attrs: { type: "flex", gutter: [16, 16] }
                    },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table2",
                              onSelectChange: null,
                              selectedRowKeys: null,
                              dataSource: _vm.dataSourceCompanyBank,
                              columns: _vm.columnsTableCompany,
                              scroll: { x: 2200 },
                              paginationcustom: true,
                              defaultPagination: false,
                              handleSwitch: _vm.handleSwitchTable,
                              handleInput: _vm.handleInput,
                              handleSelect: _vm.handleSelect,
                              handleSearchSelectTable:
                                _vm.handleSearchSelectTable,
                              loading: _vm.loadingTableCompany
                            },
                            on: { "on-edit": _vm.reponseEditTable }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c("Pagination", {
                            attrs: {
                              total: _vm.totalElements,
                              pageSizeSet: _vm.limit,
                              idPagination: "pagination1"
                            },
                            on: {
                              "response-pagesize-change":
                                _vm.responsePageSizeChange,
                              "response-currentpage-change":
                                _vm.responseCurrentPageChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          staticClass: "mt-2",
                          attrs: { span: 12, align: "end" }
                        },
                        [
                          _c(
                            "a-tag",
                            {
                              staticStyle: { "font-size": "13px" },
                              attrs: { color: "grey" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("lbl_total_found")) +
                                  " : " +
                                  _vm._s(_vm.totalElements) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            keyboard: false,
            title: _vm.$t("lbl_edit_master_bank")
          },
          model: {
            value: _vm.visibleMasterBank,
            callback: function($$v) {
              _vm.visibleMasterBank = $$v
            },
            expression: "visibleMasterBank"
          }
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                layout: "vertical",
                form: _vm.formModalBankMaster,
                "label-col": { span: 8 },
                "wrapper-col": { span: 14 }
              }
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t(_vm.formRules.name.label) } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.name.decorator,
                                expression: "formRules.name.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.name.name,
                              placeholder: _vm.$t(
                                _vm.formRules.name.placeholder
                              ),
                              autocomplete: "off"
                            },
                            on: {
                              blur: function($event) {
                                return _vm.handleValidation(
                                  $event,
                                  "name",
                                  true,
                                  "master",
                                  "wordOnly"
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t(_vm.formRules.code.label) } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.code.decorator,
                                expression: "formRules.code.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.code.name,
                              placeholder: _vm.$t(
                                _vm.formRules.code.placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.country.label) }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.country.decorator,
                                expression: "formRules.country.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.country.name,
                              placeholder: _vm.$t(
                                _vm.formRules.country.placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.active.label) }
                        },
                        [
                          _c("a-switch", {
                            model: {
                              value: _vm.isActiveMasterBank,
                              callback: function($$v) {
                                _vm.isActiveMasterBank = $$v
                              },
                              expression: "isActiveMasterBank"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _vm.$can("update", "bank")
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitForm("update")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            keyboard: false,
            title: _vm.$t(_vm.titleCompanyBank)
          },
          model: {
            value: _vm.visibleCompanyBank,
            callback: function($$v) {
              _vm.visibleCompanyBank = $$v
            },
            expression: "visibleCompanyBank"
          }
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                layout: "vertical",
                form: _vm.formModalCompanyBank,
                "label-col": { span: 8 },
                "wrapper-col": { span: 14 }
              }
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.bankName.label
                            )
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value:
                                    _vm.formRulesCompanyBank.bankName.decorator,
                                  expression:
                                    "formRulesCompanyBank.bankName.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRulesCompanyBank.bankName.name,
                                placeholder: _vm.$t(
                                  _vm.formRulesCompanyBank.bankName.placeholder
                                ),
                                showSearch: true,
                                "filter-option": false,
                                loading: _vm.loadingMasterBank,
                                allowClear: true
                              },
                              on: { search: _vm.getListMasterBankForCompany }
                            },
                            _vm._l(_vm.dataBank.data, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.name } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + data.name) + " "
                                        )
                                      ]),
                                      _vm._v(" " + _vm._s("" + data.name) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.bankBranchName.label
                            )
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRulesCompanyBank.bankBranchName
                                    .decorator,
                                expression:
                                  "formRulesCompanyBank.bankBranchName.decorator"
                              }
                            ],
                            attrs: {
                              name:
                                _vm.formRulesCompanyBank.bankBranchName.name,
                              placeholder: _vm.$t(
                                _vm.formRulesCompanyBank.bankBranchName
                                  .placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.country.label
                            )
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRulesCompanyBank.country.decorator,
                                expression:
                                  "formRulesCompanyBank.country.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRulesCompanyBank.country.name,
                              placeholder: _vm.$t(
                                _vm.formRulesCompanyBank.country.placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.bankAccountNumber.label
                            )
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRulesCompanyBank.bankAccountNumber
                                    .decorator,
                                expression:
                                  "formRulesCompanyBank.bankAccountNumber.decorator"
                              }
                            ],
                            attrs: {
                              name:
                                _vm.formRulesCompanyBank.bankAccountNumber.name,
                              placeholder: _vm.$t(
                                _vm.formRulesCompanyBank.bankAccountNumber
                                  .placeholder
                              ),
                              autocomplete: "off"
                            },
                            on: {
                              blur: function($event) {
                                return _vm.handleValidation(
                                  $event,
                                  "bankAccountNumber",
                                  true,
                                  "company",
                                  "numberOnly"
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.bankAccountName.label
                            )
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRulesCompanyBank.bankAccountName
                                    .decorator,
                                expression:
                                  "formRulesCompanyBank.bankAccountName.decorator"
                              }
                            ],
                            attrs: {
                              name:
                                _vm.formRulesCompanyBank.bankAccountName.name,
                              placeholder: _vm.$t(
                                _vm.formRulesCompanyBank.bankAccountName
                                  .placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.currency.label
                            )
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value:
                                    _vm.formRulesCompanyBank.currency.decorator,
                                  expression:
                                    "formRulesCompanyBank.currency.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRulesCompanyBank.currency.name,
                                placeholder: _vm.$t(
                                  _vm.formRulesCompanyBank.currency.placeholder
                                ),
                                showSearch: true,
                                "filter-option": false,
                                loading: _vm.loadingCurrency,
                                allowClear: true
                              },
                              on: { search: _vm.getCurrency }
                            },
                            _vm._l(_vm.dataCurrency, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(data.currencyCode) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(data.currencyCode) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.bankAccountControl.label
                            )
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value:
                                    _vm.formRulesCompanyBank.bankAccountControl
                                      .decorator,
                                  expression:
                                    "formRulesCompanyBank.bankAccountControl.decorator"
                                }
                              ],
                              attrs: {
                                name:
                                  _vm.formRulesCompanyBank.bankAccountControl
                                    .name,
                                placeholder: _vm.$t(
                                  _vm.formRulesCompanyBank.bankAccountControl
                                    .placeholder
                                ),
                                showSearch: true,
                                "filter-option": false,
                                loading: _vm.loadingCoa,
                                allowClear: true
                              },
                              on: { search: _vm.getListOfCoa }
                            },
                            _vm._l(_vm.dataCoa.data, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              data.code + " " + data.description
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            data.code + " " + data.description
                                          ) +
                                          " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.companyBankType.label
                            )
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value:
                                    _vm.formRulesCompanyBank.companyBankType
                                      .decorator,
                                  expression:
                                    "formRulesCompanyBank.companyBankType.decorator"
                                }
                              ],
                              attrs: {
                                name:
                                  _vm.formRulesCompanyBank.companyBankType.name,
                                placeholder: _vm.$t(
                                  _vm.formRulesCompanyBank.companyBankType
                                    .placeholder
                                ),
                                showSearch: false,
                                allowClear: true
                              }
                            },
                            _vm._l(_vm.dataCompanyBankType, function(
                              data,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(data.option) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(data.option) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.swiftCode.label
                            )
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRulesCompanyBank.swiftCode.decorator,
                                expression:
                                  "formRulesCompanyBank.swiftCode.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRulesCompanyBank.swiftCode.name,
                              placeholder: _vm.$t(
                                _vm.formRulesCompanyBank.swiftCode.placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.payables.label
                            )
                          }
                        },
                        [
                          _c("a-switch", {
                            model: {
                              value: _vm.isPayable,
                              callback: function($$v) {
                                _vm.isPayable = $$v
                              },
                              expression: "isPayable"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.receivables.label
                            )
                          }
                        },
                        [
                          _c("a-switch", {
                            model: {
                              value: _vm.isReceivables,
                              callback: function($$v) {
                                _vm.isReceivables = $$v
                              },
                              expression: "isReceivables"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRulesCompanyBank.active.label)
                          }
                        },
                        [
                          _c("a-switch", {
                            model: {
                              value: _vm.isActiveCompany,
                              callback: function($$v) {
                                _vm.isActiveCompany = $$v
                              },
                              expression: "isActiveCompany"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRulesCompanyBank.thirdApps.label
                            )
                          }
                        },
                        [
                          _c("a-select", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRulesCompanyBank.thirdApps.decorator,
                                expression:
                                  "formRulesCompanyBank.thirdApps.decorator"
                              }
                            ],
                            attrs: {
                              options: _vm.thirdAppOptions,
                              "allow-clear": "",
                              mode: "multiple",
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t(
                                  _vm.formRulesCompanyBank.thirdApps.label
                                )
                              })
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleCancelModalCompany }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              ),
              _vm.$can("create", "bank") || _vm.$can("update", "bank")
                ? _c(
                    "a-button",
                    {
                      key: "ok",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitFormCompany }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.mode === "create"
                              ? _vm.$t("lbl_create")
                              : _vm.$t("lbl_update")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }